import React from 'react';

const PrivacyNoticeModal = ({ show, onClose }) => {
    if (!show) {
        return null;
    }
    return (
        <div id='privacyNoticeModal'>
            <span id="closeBtn" className="material-symbols-outlined" onClick={onClose}>
                close
            </span>
            <p>
                개인정보처리방침
                <br />
                <br />
                <br />
                주식회사 종명건설(이하 ‘회사’)은(는) 사용자의 개인정보를 중요하게 생각하며, 개인정보 보호법 제30조를 준수하여 개인정보를 처리하고 있습니다. 본 개인정보처리방침은 회사가 제공하는 서비스(웹사이트) 이용과 관련하여 사용자의 개인정보가 어떻게 수집, 이용, 공유 및 보호되는지를 안내합니다.
                <br />
                <br />
                <br />
                [제1조]수집하는 개인정보 항목
                회사는 서비스 제공을 위해 다음과 같은 개인정보를 수집할 수 있습니다:
                - 필수 정보: 이름, 연락처
                - 자동 수집 정보: IP 주소, 쿠키, 방문 기록, 서비스 이용 기록
                <br />
                <br />
                <br />
                [제2조] 개인정보 수집 방법
                회사는 다음과 같은 방법으로 개인정보를 수집합니다:
                - 서비스 이용 시 사용자가 직접 입력한 정보
                - 서비스 이용 과정에서 자동으로 수집되는 정보(쿠키, 로그 분석 도구 등을 통해)
                <br />
                <br />
                <br />
                [제3조] 개인정보의 이용 목적
                수집한 개인정보는 다음과 같은 목적으로 이용됩니다:
                - 서비스 제공 및 운영
                - 고객 관리 및 문의 대응
                - 서비스 개선 및 맞춤형 서비스 제공
                - 법적 의무 이행 및 분쟁 해결
                <br />
                <br />
                <br />
                [제4조] 개인정보의 보유 및 이용 기간
                회사는 원칙적으로 개인정보 수집 및 이용 목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 다만, 법령에 따라 보관해야 할 경우에는 법령에서 정한 기간 동안 개인정보를 보관합니다:
                - 계약 또는 청약철회 등에 관한 기록: 5년
                - 소비자의 불만 또는 분쟁 처리에 관한 기록: 3년
                - 웹사이트 방문 기록: 3개월
                <br />
                <br />
                <br />
                [제5조] 개인정보의 제3자 제공
                회사는 사용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 다음의 경우에 한해 개인정보를 제3자에게 제공할 수 있습니다:
                - 사용자가 사전에 동의한 경우
                - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
                <br />
                <br />
                <br />
                [제6조] 개인정보의 처리 위탁
                회사는 원활한 개인정보 업무 처리를 위해 개인정보 처리를 위탁할 수 있습니다. 위탁 계약 시 개인정보보호 관련 법규를 준수하며, 개인정보가 안전하게 관리되도록 필요한 조치를 하고 있습니다.
                <br />
                <br />
                - 위탁받는 자 : 종명건설 고객센터
                <br />
                <br />
                <br />
                [제7조] 개인정보의 파기 절차 및 방법
                회사는 원칙적으로 개인정보의 처리 목적이 달성되면 지체 없이 해당 개인정보를 파기합니다. 파기의 절차 및 방법은 다음과 같습니다:
                - 파기 절차: 수집된 정보는 목적 달성 후 별도의 데이터베이스(DB)로 옮겨져 내부 방침 및 법령에 따라 일정 기간 저장된 후 파기됩니다.
                - 파기 방법: 전자적 파일 형태의 정보는 복구할 수 없는 기술적 방법을 사용하여 삭제하며, 종이 문서 형태의 정보는 분쇄기로 분쇄하거나 소각합니다.
                <br />
                <br />
                <br />
                [제8조] 이용자의 권리와 그 행사 방법
                이용자는 언제든지 개인정보에 대한 열람, 수정, 삭제, 처리 정지 요청을 할 수 있습니다. 이러한 권리 행사는 회사의 고객센터 또는 이메일을 통해 가능합니다.
                <br />
                <br />
                <br />
                [제9조] 개인정보 보호를 위한 기술적/관리적 대책
                회사는 사용자의 개인정보를 보호하기 위해 다음과 같은 조치를 취하고 있습니다:
                - 개인정보 암호화
                - 해킹 및 컴퓨터 바이러스 방지 대책
                - 접근 통제 및 권한 관리
                <br />
                <br />
                <br />
                [제10조] 쿠키 사용 안내
                회사는 맞춤형 서비스를 제공하기 위해 쿠키를 사용할 수 있습니다. 쿠키는 사용자의 컴퓨터에 저장되는 작은 텍스트 파일로, 사용자에게 보다 빠르고 편리한 웹사이트 이용 환경을 제공하기 위해 사용됩니다. 사용자는 쿠키 사용을 거부할 수 있으며, 쿠키 설정은 웹 브라우저의 옵션에서 변경할 수 있습니다.
                <br />
                <br />
                <br />
                [제11조] 개인정보 보호책임자 및 담당자 연락처
                - 개인정보 보호책임자 : 김예원
                - 연락처 : jongm5@naver.com, 02-487-8346
                <br />
                <br />
                <br />
                [제12조] 개인정보처리방침 변경
                본 개인정보처리방침은 법령 또는 회사 정책에 따라 변경될 수 있으며, 변경 시에는 변경 사항을 공지합니다.
                <br />
                <br />
                <br />
                본 개인정보 처리방침은 2024년 9월 1일부터 시행합니다.
            </p>
        </div>
    );
}

export default PrivacyNoticeModal;