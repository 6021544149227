import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdminAuth = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        console.log("token : " + token);
        if(!token) {
            navigate('/goAdminPage');
        } else {
            // axios.get('http://localhost:8081/admin/validate', {
            axios.get('https://www.jongmm.com/admin/validate', {
                headers: { 'Authorization': `Bearer ${token}` }
            }).then(response => {
                console.log("response.data.valid : " + response.data.valid);
                if(!response.data.valid) {
                    localStorage.removeItem('token');
                    navigate('/goAdminPage');
                }
            }).catch(error => {
                console.error('Token validation error', error);
                localStorage.removeItem('token');
                navigate('/goAdminPage');
            });
        }
    }, [navigate]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/goAdminPage');
    };

    return { handleLogout };
};

export default AdminAuth;