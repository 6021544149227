import React from "react";
import '../asset/css/SearchBar.css'

const SearchBar = ({ searchTerm, handleSearchTermChange }) => {
    return (
        <div id="searchBox">
            <input
                type="text"
                id="searchTerm"
                value={searchTerm}
                onChange={handleSearchTermChange}
                placeholder="검색어를 입력하세요."
            />
            <span class="material-symbols-outlined">search</span>
        </div>
    );
};

export default SearchBar;