import React from "react";

const SortDropdown = ({ sortOrder, handleSortOrderChange }) => {
    const styles = {
        sortOrder: {
            border: 'none',
            color: '#939393',
            fontSize: '15px'
        }
    }
    return (
        <div id="sortBox">
            <select id='sortOrder' value={sortOrder} onChange={handleSortOrderChange} style={styles.sortOrder}>
                <option value="latest">최신순</option>
                <option value="oldest">오래된 순</option>
            </select>
        </div>
    )
}

export default SortDropdown;