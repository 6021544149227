import React, { useEffect, useState } from 'react'
import '../asset/css/Footer.css'
import logo from '../asset/image/jmcIcon.png';
import Modal from 'react-modal'

import blog from '../asset/image/blogIcon.png'
import youtube from '../asset/image/youtubeIcon.png'
import PrivacyNoticeModal from '../components/PrivacyNoticeModal';
import EmailCollectionBanModal from '../components/EmailCollectionBanModal';

const Footer = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const openModal = (contentType) => {
        setModalContent(contentType);
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent(null);
    }

    return (
        <div id='footerBox'>
            <div class="footer-container">
                <div id='footerPolicies'>
                    <p id='emailCollectionRefusal' onClick={() => openModal('emailBan')}>이메일무단수집거부</p>
                    <p id='privacyPolicy' onClick={() => openModal('privacyPolicy')}>개인정보처리방침</p>
                </div>
                <div class="footer-info">
                    {/* <img id='logo' src={logo}></img> */}
                    <p class="footer-company-name"><span>주식회사 종명건설</span><span id='isMobileHidden'> &nbsp; | &nbsp; 대표 : 김예원 &nbsp; 사업자번호 : 212-81-37545</span></p>
                    <p class="footer-address"><span id='isMobileHidden'>주소 : </span>07806 서울특별시 강서구 공항대로 222, 708호</p>
                    <p class="footer-fax-mail"><span id='isMobileHidden'>TEL : 02)487-8346 &nbsp; </span> FAX : 02)487-8348 &nbsp; <span id='isMobileShow'><br /></span>MAIL : jongm5@naver.com</p>
                </div>
                <div id='rightBox'>
                    <p id='phontText'>대표전화</p><span id='isMobileShow'>&nbsp;</span>
                    <p id='phone'>02)487-8346</p>
                    <p id='detailText'><span id='isMobileHidden'>상담 : </span>평일 09:00 ~ 18:00 (점심시간 : 12:00 ~ 13:00)<br />휴무 : 토, 일요일 및 공휴일</p>
                </div>
                <div id='line'></div>
                <div className='footer-copyright'>
                    <p>Copyright © 2024 Jongmyeong Construction Corp. All Rights Reserved.</p>
                </div>
                <div id='iconBox'>
                    <a href='https://blog.naver.com/jongm5' target='_blank' rel='noopener noreferrer'>
                        <img className='icon' src={blog} alt='Go Blog' />
                    </a>
                    <a href='https://www.youtube.com/@youngwon2064' target='_blank' rel='noopener noreferrer'>
                        <img className='icon' src={youtube} alt='Go Youtube' />
                    </a>
                </div>
            </div>
            <Modal id='footerModal' isOpen={isModalOpen} onRequestClose={closeModal}
                style={{
                    overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: '#000000ad',
                    height: '100%',
                    width: '100%'
                    },
                    content: {
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    border: '0px solid red',
                    backgroundColor: 'white',
                    width: '30%',
                    height: '65%',
                    placeSelf: 'center',
                    zIndex: 2,
                    overflow: 'scroll',
                    boxShadow: '1px 3px 12px #00000029',
                    top: '5vh',
                    inset: '5vh 40px 40px 40px'
                    }
                }}>
                {modalContent === 'privacyPolicy' && (
                    <PrivacyNoticeModal show={isModalOpen} onClose={closeModal}/>

                )}
                {modalContent === 'emailBan' && (
                    <EmailCollectionBanModal show={isModalOpen} onClose={closeModal} />
                )}
            </Modal>
        </div>
    )
}

export default Footer;