import React from 'react';

const PerformanceModal = ({ show, onClose, application }) => {
    if (!show || !application) {
        return null;
    }

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('ko-KR', options);
    };

    const convertNewlinesToBreaks = (text) => {
        return text.split('\n').map((line, index) => (
            <span key={index}>
                {line}
                <br />
            </span>
        ));
    };

    const detailImages = application.detail_images ? application.detail_images.split('|') : [];
    const detailTexts = application.detail_texts ? application.detail_texts.split('|') : [];

    return (
        <div id='performanceModal'>
            <span id="closeBtn" className="material-symbols-outlined" onClick={onClose}>
                close
            </span>
            <p id='dateText'>{formatDate(application.upload_date)}</p>
            <p id='performanceModalTitle'>{application.title}</p>
            <p id='views'>조회수 : {application.views}</p>
            <div id='line'></div>

            {detailImages.map((image, index) => (
                <div key={index} className='detailBox'>
                    <img 
                        className='detailImage' 
                        src={image.startsWith('data:image/') ? image : `/uploads/${image}`} 
                        alt={`상세 이미지${index + 1}`} 
                    />
                    <p className='detailText'>{convertNewlinesToBreaks(detailTexts[index] || '')}</p>
                </div>
            ))}
        </div>
    );
}

export default PerformanceModal;