import React from 'react'
import '../asset/css/Product.css'
import Header from '../layout/Header'
import Nav from '../layout/Nav'
import Footer from '../layout/Footer'
import HeaderImage from '../asset/image/ProductHeader.png'
import ProductImg1 from '../asset/image/product/그림1.png'
import ProductImg2 from '../asset/image/product/그림2.png'

import ProductImg3 from '../asset/image/product/홈페이지.png'

import ProductDetail1 from '../asset/image/product/productDetail1.png'
import ProductDetail2 from '../asset/image/product/productDetail2.png'
import ProductDetail3 from '../asset/image/product/productDetail3.png'
import ProductDetail4 from '../asset/image/product/productDetail4.png'

const Product = () => {
    return (
        <div>
            <Nav />
            <Header />
            <div id='header'>
                <img id='headerImage' src={HeaderImage} />
            </div>
            <div id='aboutTitleBox'>
                <p className='pageTitle'><span>Our method</span><br />공법소개</p>
                <p id='line'></p>
            </div>
            <div className='productBox' id='productBox1'>
                <div id='textBox'>
                    <p id='title'>PVC시트 방수공법이란?</p>
                    <p id='detailText'>• PVC시트와 콘크리트 구조체를 화스너와 메탈플레이트를 이용하여 고정하고, 시트 간의 이음매는 고온의 열풍융착기로 용접하는 방식의 비접착식 건식공법입니다.</p>
                </div>
                <div id='productImgBox'>
                    <img id='img1' src={ProductImg2} />
                    <img id='img2' src={ProductImg1} />
                </div>

                <p id='title'>PVC시트 방수공법의 특징</p>
                <div id='detailBox'>
                    <img id='productDetailImg' src={ProductImg3} />
                    <div id='detailImgBox'>
                        <img src={ProductDetail1} />
                        <img src={ProductDetail2} />
                        <img src={ProductDetail3} />
                        <img src={ProductDetail4} />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Product;