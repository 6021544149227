import './App.css';
import Home from './pages/Home';
import About from './pages/About';
// import Inquiry from './pages/Inquiry';
import Performance from './pages/Performance';
import Product from './pages/Product';
// import Recruitment from './pages/Recruitment';
import { BrowserRouter, Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import React from 'react';
import AdminLogin from './pages/admin/AdminLogin';
import Admin from './pages/admin/Admin';
import JobPostingUpdate from './pages/admin/JobPostingUpdate';
import JobApplications from './pages/admin/JobApplications';
import QuoteInquiry from './pages/admin/QuoteInquiry';
import PerformanceUpdate from './pages/admin/PerformanceUpdate';
import PerformanceList from './pages/admin/PerformanceList';
import RooftopWaterproofing from './pages/product/RooftopWaterproofing'
import CommercialKitchenWaterproofing from './pages/product/CommercialKitchenWaterproofing'
import PoolWaterproofing from './pages/product/PoolWaterproofing'

function App() {
  return (
    // <Router>
    <div>
      {/* <BrowserRouter basename={process.env.PUBLIC_URL}> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          {/* <Route exact path="/inquiry" element={<Inquiry />} /> */}
          <Route exact path="/performance" element={<Performance />} />
          <Route exact path="/product" element={<Product />} />
          <Route exact path="/goAdminPage" element={<AdminLogin />} />
          <Route exact path="/adminPage" element={<Admin />} />
          <Route exact path='/jobPostingUpdate' element={<JobPostingUpdate />} />
          <Route exact path='/jobApplications' element={<JobApplications />} />
          <Route exact path='/quoteInquiry' element={<QuoteInquiry />} />
          <Route exact path='/performanceUpdate' element={<PerformanceUpdate />} />
          <Route exact path='/performanceList' element={<PerformanceList />} />
          <Route exact path='/rooftopWaterproofing' element={<RooftopWaterproofing />} />
          <Route exact path='/commercialKitchenWaterproofing' element={<CommercialKitchenWaterproofing />} />
          <Route exact path='/poolWaterproofing' element={<PoolWaterproofing />} />
          {/* <Route exact path="/recruitment" element={<Recruitment />} /> */}
        </Routes>
      {/* </BrowserRouter> */}
    </div>
    // </Router>
  );
}

export default App;