import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';
import '../asset/css/Nav.css';
import menuBtnImg from '../asset/image/commerce_Icon.png'
import scrolledMenuBtnImg from '../asset/image/commerce_IconW.png'
import Inquiry from '../pages/Inquiry';
import Down from '../asset/image/arrowDownW.png'
import Up from '../asset/image/arrowUpW.png'
import JmcIcon from '../asset/image/jmcIconW.png'

const MenuList = ({closeModal}) => (
    <div id='menuList'>
        <span id="closeBtn" class="material-symbols-outlined" onClick={closeModal}>
        close
        </span>
        <ul>
            <li><a href='about'>회사소개</a></li>
            <div className='line'></div>
            <li><a href='product'>공법소개</a></li>
            <li id='product'>시공분야<img id='arrowDown' src={Down} /><img id='arrowUp' src={Up} /></li>
            <div id='productList'>
                <li className='productMenu'>
                    <a id='productMenuLink' href='rooftopWaterproofing'>옥상 시트방수</a>
                </li>
                <li className='productMenu'>
                    <a id='productMenuLink' href='commercialKitchenWaterproofing'>상업용 주방 시트방수</a>
                </li>
                <li className='productMenu'>
                    <a id='productMenuLink' href='poolWaterproofing'>수영장 시트방수</a>
                </li>
            </div>
            <div className='line'></div>
            <li><a href='performance'>시공실적</a></li>
            <div className='line'></div>
            <li id='inquiry' onClick={openInquiryModal}>견적문의</li>
            <div className='line'></div>
        </ul>
    </div>
);

const Nav = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const [isInquiryModalOpen, setInquiryModalOpen] = useState(false);

    const openInquiryModal = () => {
        setInquiryModalOpen(true);
    };

    const closeInquiryModal = () => {
        setInquiryModalOpen(false);
    };

    const [menuBtnImgSrc, setMenuBtnImgSrc] = useState(menuBtnImg);

    const location = useLocation(); // 현재 경로 가져오기

    useEffect(() => {
        if (location.pathname === '/') {
            setMenuBtnImgSrc(scrolledMenuBtnImg); // 현재 경로가 '/home'일 때만 이미지 변경
        } else {
            setMenuBtnImgSrc(menuBtnImg); // 다른 경로에서는 기본 이미지 사용
        }
    }, [location.pathname]); // 경로가 변경될 때마다 useEffect 실행

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setMenuBtnImgSrc(scrolledMenuBtnImg);
            } else {
                setMenuBtnImgSrc(menuBtnImg);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div id='navBox'>
            <img src={menuBtnImgSrc} onClick={openModal} id='navBtn' />
            {/* <span onClick={openModal} id='navBtn' className="material-symbols-outlined">
            menu
            </span> */}
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal}
            style={{
                overlay: {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0)',
                height: '100%',
                width: '100%'
                },
                content: {
                position: 'absolute',
                top: '0',
                left: '0',
                // right: '0',
                // bottom: '40px',
                border: '0px solid #ccc',
                // background: '#fff',
                // overflow: 'auto',
                // WebkitOverflowScrolling: 'touch',
                // borderRadius: '4px',
                // outline: 'none',
                // padding: '20px'
                backgroundColor: 'rgba(0,0,0,0)',
                width: '22%',
                height: '50%'
                }
            }}>
            {/* > */}
                {/* <MenuList closeModal={closeModal} /> */}
                <div id='menuList'>
                    <img id='navJmcIcon' src={JmcIcon} />
                    <span id="closeBtn" class="material-symbols-outlined" onClick={closeModal}>
                    close
                    </span>
                    <ul>
                        <li><a href='about'>회사소개</a></li>
                        <div className='line'></div>
                        <li><a href='product'>공법소개</a></li>
                        <div className='line'></div>
                        <li>
                            <input type="checkbox" id="menuBtn" />
                            <label for="menuBtn" id='productBtn' onclick="">시공분야</label>
                            <label for="menuBtn"><img id='arrowDown' src={Down} /></label>
                            <label for="menuBtn"><img id='arrowUp' src={Up} /></label>
                            <div id='productListNav'>
                                <li className='productMenu'> 
                                    <a id='productMenuLink' href='rooftopWaterproofing'>옥상 시트방수</a>
                                </li>
                                <li className='productMenu'>
                                    <a id='productMenuLink' href='commercialKitchenWaterproofing'>상업용 주방 시트방수</a>
                                </li>
                                <li className='productMenu'>
                                    <a id='productMenuLink' href='poolWaterproofing'>수영장 시트방수</a>
                                </li>
                            </div>
                        </li>
                        <div className='line'></div>
                        <li><a href='performance'>시공실적</a></li>
                        <div className='line'></div>
                        <li id='inquiry' onClick={openInquiryModal}>견적문의</li>
                        <div className='line'></div>
                    </ul>
                </div>
                <Inquiry modalIsOpen={isInquiryModalOpen} closeModal={closeInquiryModal}/>
            </Modal>
        </div>
    );
};

export default Nav;