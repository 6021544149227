import React, { useEffect, useState } from 'react'
import AdminAuth from './AdminAuth';
import axios from 'axios';
import CheckBox from '../../components/CheckBox';
import SearchBar from '../../components/SearchBar';
import SortDropdown from '../../components/SortDropdown';

const QuoteInquiry = () => {
    const { handleLogout } = AdminAuth();

    const [quoteApplications, setQuoteApplications] = useState([]);
    const [sortOrder, setSortOreder] = useState('latest');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedWorkCategory, setSelectedWorkCategory] = useState([]);
    const [selectedFacilityTypeCategory, setSelectedFacilityTypeCategory] = useState([]);

    useEffect(() => {
        // axios.get('http://localhost:8081/admin/getAllQuoteInquirys')
        axios.get('https://www.jongmm.com/admin/getAllQuoteInquirys')
            .then(response => {
                const sortedApplications = response.data.sort((a,b) => {
                    if (sortOrder === 'latest') {
                        return new Date(b.applied_at) - new Date(a.applied_at);
                    } else {
                        return new Date(a.applied_at) - new Date(b.applied_at);
                    }
                });
                setQuoteApplications(sortedApplications);
            })
            .catch(error => {
                console.error("견적 신청서를 불러올 수 없습니다! 개발자에게 문의하세요."), error;
            });
    }, [sortOrder]);

    const handleSortOrderChange = (event) => {
        setSortOreder(event.target.value);
    };
    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleSelectedStatus = (selected) => {
        setSelectedStatus(selected);
    };
    const handleSelectedWorkCategory = (selected) => {
        setSelectedWorkCategory(selected);
    }
    const handleSelectedFacilityTypeCategory = (selected) => {
        setSelectedFacilityTypeCategory(selected);
    }

    const filteredApplications = quoteApplications.filter(application => 
        (selectedFacilityTypeCategory.length === 0 || selectedFacilityTypeCategory.includes(application.facilityTypeCategory)) &&
        (selectedWorkCategory.length === 0 || selectedWorkCategory.includes(application.workCategory)) &&
        (selectedStatus.length === 0 || selectedStatus.includes(application.status)) &&
        (searchTerm === '' ||
            application.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            application.contact.includes(searchTerm) ||
            application.requestDetails.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const toggleStatus = (id, currentStatus) => {
        const newStatus = currentStatus === 1 ? 0 : 1;
        // axios.post('http://localhost:8081/admin/toggleQuoteStatus', {id, status: newStatus})
        axios.post('https://www.jongmm.com/admin/toggleQuoteStatus', {id, status: newStatus})
            .then(response => {
                setQuoteApplications(prevApplications =>
                    prevApplications.map(application =>
                        application.id === id ? { ...application, status: newStatus } : application
                    )
                );
            })
            .catch(error => {
                console.error("상태를 변경할 수 없습니다! 개발자에게 문의하세요.", error);
            });
    };

    return (
        <div>
            <h1>견적 신청 페이지입니다.</h1>
            <SortDropdown sortOrder={sortOrder} handleSortOrderChange={handleSortOrderChange} />
            <SearchBar searchTerm={searchTerm} handleSearchTermChange={handleSearchTermChange} />
            <CheckBox handleSelectedItems={handleSelectedStatus} data = {[
                { id: 1, title: '완료' },
                { id: 0, title: '완료 취소' }
            ]} />
            <CheckBox handleSelectedItems={handleSelectedWorkCategory} data = {[
                { id: 1, title: '도장' },
                { id: 2, title: '방수' },
                { id: 3, title: '기타' }
            ]} />
            <CheckBox handleSelectedItems={handleSelectedFacilityTypeCategory} data = {[
                { id: 1, title: '아파트' },
                { id: 2, title: '일반 건물' },
                { id: 3, title: '기타' }
            ]} />
            <div>
                <ul>
                    {filteredApplications.map(application => (
                        <li key={application.id}>
                            <p>신청자 : {application.name}</p>
                            <p>연락처 : {application.contact}</p>
                            <p>시공시설 : {application.facility_type}</p>
                            <p>작업 카테고리 : {application.work_type}</p>
                            <p>의뢰 내용 : {application.requestDetails}</p>
                            <p>신청 일시 : {application.applied_at}</p>
                            <button onClick={() => toggleStatus(application.id, application.status)}>{application.status === 1 ? '완료' : '완료 취소'}</button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default QuoteInquiry;