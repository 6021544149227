import React from 'react'
import '../../asset/css/CommercialKitchenWaterproofing.css'
import Header from '../../layout/Header'
import Nav from '../../layout/Nav'
import Footer from '../../layout/Footer'
import HeaderImage from '../../asset/image/CommercialKitchenWaterproofingHeader.png'
import kitchenImg1 from '../../asset/image/kitchenWaterproofing1.png'
import kitchenImg2 from '../../asset/image/kitchenWaterproofing2.png'
import arrow from '../../asset/image/화살표.png'

import before1 from '../../asset/image/kitchenWaterproofing/beforeImg1.png'
import after1 from '../../asset/image/kitchenWaterproofing/afterImg1.png'
import before2 from '../../asset/image/kitchenWaterproofing/beforeImg2.png'
import after2 from '../../asset/image/kitchenWaterproofing/afterImg2.png'
import before3 from '../../asset/image/kitchenWaterproofing/beforeImg3.png'
import after3 from '../../asset/image/kitchenWaterproofing/afterImg3.png'
import before4 from '../../asset/image/kitchenWaterproofing/beforeImg4.png'
import after4 from '../../asset/image/kitchenWaterproofing/afterImg4.png'
import before5 from '../../asset/image/kitchenWaterproofing/beforeImg5.png'
import after5 from '../../asset/image/kitchenWaterproofing/afterImg5.png'
import before6 from '../../asset/image/kitchenWaterproofing/beforeImg6.png'
import after6 from '../../asset/image/kitchenWaterproofing/afterImg6.png'

const CommercialKitchenWaterproofing = () => {
    return (
        <div id='commercialKitchenWaterproofing'>
            <Nav />
            <Header />
            <div id='header'>
                <img id='headerImage' src={HeaderImage} />
            </div>
            <div id='aboutTitleBox'>
                <p className='pageTitle'><span>Waterproof of kitchen</span><br />상업용 주방 시트방수</p>
                <p id='line'></p>
            </div>
            <div className='productBox'>
                <div id='textBox'>
                    <p id='title'>기존 주방의 문제점</p>
                </div>
                <div id='kitchenImgBox'>
                    <img src={kitchenImg1} />
                    <img src={kitchenImg2} />
                </div>
                <p id='kitchenImgText'>타일이 깨지고 트랜치 주변 메지가 벌어지면서 누수가 발생됩니다. 주방 누수는 식당 홀이나 건물 내로 퍼지면서 추가적인 2차 피해를 발생 시킬 수 있습니다.</p>
            </div>
            <div id='constructionExamplesBox'>
                <div className='examplesBox'>
                    <p id='title'>PVC시트 시공사례</p>
                    <p id='title1'>01 | 공장 구내식당 조리실</p>
                    <p id='detailText'>구내식당 내부 홀까지 물이 흘러 식당 사용자들에게 피해가 되었고, 건물 아래층까지 누수가 되었던 현장입니다. 공장 조리 일정에 지장 없도록 주말동안 작업하여 누수를 해결하였습니다.</p>
                    <div id='examplesImgBox'>
                        <div id='beforeBox'>
                            <img src={before1} />
                            <p>트랜치 부위 시공 전</p>
                        </div>
                        <div id='arrowBox'>
                            <img src={arrow} />
                        </div>
                        <div id='afterBox'>
                            <img src={after1} />
                            <p>트랜치 부위 시공 후</p>
                        </div>
                    </div>
                    <div id='examplesImgBox'>
                        <div id='beforeBox'>
                            <img src={before2} />
                            <p>조리기구 부위 시공 전</p>
                        </div>
                        <div id='arrowBox'>
                            <img src={arrow} />
                        </div>
                        <div id='afterBox'>
                            <img src={after2} />
                            <p>조리기구 부위 시공 후</p>
                        </div>
                    </div>
                </div>
                <div className='examplesBox'>
                    <p id='title1'>02 | 병원 조리실</p>
                    <p id='detailText'>건물 내로 누수가 진행 중이었고, 하루라도 조리실 운영을 중단할 수 없었던 상황에 맞춰 야간작업으로 시공하였습니다. 누수로 인해 아래층 고가의 장비나 시설물에 영향을 주어 2차 피해가 발생할 수 있었지만 한번의 시공으로 누수를 해결하였습니다.</p>
                    <div id='examplesImgBox'>
                        <div id='beforeBox'>
                            <img src={before3} />
                            <p>PVC시트 시공 전</p>
                        </div>
                        <div id='arrowBox'>
                            <img src={arrow} />
                        </div>
                        <div id='afterBox'>
                            <img src={after3} />
                            <p>PVC시트 시공 후</p>
                        </div>
                    </div>
                    <div id='examplesImgBox'>
                    <div id='beforeBox'>
                            <img src={before4} />
                            <p>PVC시트 시공 전</p>
                        </div>
                        <div id='arrowBox'>
                            <img src={arrow} />
                        </div>
                        <div id='afterBox'>
                            <img src={after4} />
                            <p>PVC시트 시공 후</p>
                        </div>
                    </div>
                </div>
                <div className='examplesBox'>
                    <p id='title1'>03 | 식당 주방</p>
                    <p id='detailText'>식당 주방에서 누수가 발생되어 건물 내 지하로 피해가 이어진 현장입니다. 대부분의 주방은 트랜치 및 타일 메지 틈새가 누수 원인입니다. PVC시트 시공 후 누수가 해결되어 피해부위 재도장 작업까지 진행할 수 있었습니다.</p>
                    <div id='examplesImgBox'>
                        <div id='beforeBox'>
                            <img src={before5} />
                            <p>PVC시트 시공 전</p>
                        </div>
                        <div id='arrowBox'>
                            <img src={arrow} />
                        </div>
                        <div id='afterBox'>
                            <img src={after5} />
                            <p>PVC시트 시공 후</p>
                        </div>
                    </div>
                    <div id='examplesImgBox'>
                    <div id='beforeBox'>
                            <img src={before6} />
                            <p>지하 화장실 천정으로 누수</p>
                        </div>
                        <div id='arrowBox'>
                            <img src={arrow} />
                        </div>
                        <div id='afterBox'>
                            <img src={after6} />
                            <p>방수 시공 후 누수 해결</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CommercialKitchenWaterproofing;