import React, { useState } from 'react';
import Modal from 'react-modal';
import '../asset/css/Inquiry.css'
import axios from "axios";

const InquiryFormModal = ({ closeModal, onInputChange, onSubmit }) => (
    <div id='InquiryForm'>
        <span id="closeBtn" className="material-symbols-outlined" onClick={closeModal}>
            close
        </span>
        <div id='formBox'>
            <h1 id='modalTitle'>견적문의</h1>
            <form onSubmit={(e) => onSubmit(e)}>
                <p id='isMobileShow'>*이름</p>
                <div className='inputTextBox'>
                    <span className="material-symbols-outlined">id_card</span>
                    <input 
                    type={'text'} 
                    className='inputText' 
                    name='name' 
                    placeholder='이름을 입력하세요.' 
                    onChange={(e) => onInputChange(e)}
                    required
                    />
                </div>
                <p id='isMobileShow'>*전화번호</p>
                <div className='inputTextBox'>
                    <span className="material-symbols-outlined">call</span>
                    <input 
                    type={'text'} 
                    className='inputText' 
                    name='contact' 
                    placeholder='전화번호를 입력하세요.' 
                    onChange={(e) => onInputChange(e)}
                    required
                    />
                </div>
                <div className='radioBox' id='facilityBox'>
                    <p><span id='isMobileShow'>*</span>건물 형태</p>
                    <input 
                    type='radio' 
                    id='apartment' 
                    name='facilityTypeCategory' 
                    value='1'
                    onChange={(e) => onInputChange(e)}
                    required
                    />
                    <label htmlFor='apartment'>아파트</label>
                    <input 
                    type='radio' 
                    id='generalBuilding' 
                    name='facilityTypeCategory' 
                    value='2'
                    onChange={(e) => onInputChange(e)}
                    />
                    <label htmlFor='generalBuilding'>일반 건물</label>
                    <input 
                    type='radio' 
                    id='facilityTypeOther' 
                    name='facilityTypeCategory' 
                    value='3'
                    onChange={(e) => onInputChange(e)}
                    />
                    <label htmlFor='facilityTypeOther'>기타</label>
                </div>
                <div className='radioBox'>
                    <p><span id='isMobileShow'>*</span>작업 내용</p>
                    <input 
                    type='radio' 
                    id='stamp' 
                    name='workCategory' 
                    value='1'
                    onChange={(e) => onInputChange(e)}
                    required
                    />
                    <label htmlFor='stamp'>도장</label>
                    <input 
                    type='radio' 
                    id='waterproof' 
                    name='workCategory' 
                    value='2'
                    onChange={(e) => onInputChange(e)}
                    />
                    <label htmlFor='waterproof'>방수</label>
                    <input 
                    type='radio' 
                    id='workOther' 
                    name='workCategory' 
                    value='3'
                    onChange={(e) => onInputChange(e)}
                    />
                    <label htmlFor='workOther'>기타</label>
                </div>
                <p><span id='isMobileShow'>*</span>문의 내용</p>
                <textarea 
                name='requestDetails' 
                cols={50} 
                rows={15} 
                placeholder='의뢰 내용' 
                onChange={(e) => onInputChange(e)}
                required
                />
                <input type='submit' value={'완료'}></input>
            </form>
        </div>
    </div>
);

const Inquiry = ({ modalIsOpen, closeModal }) => {
    const [application, setApplication] = useState({
        name: "",
        contact: "",
        facilityTypeCategory: "",
        workCategory: "",
        requestDetails: ""
    })

    const onInputChange=(e) => {
        setApplication({...application, [e.target.name]:e.target.value});
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        // await axios.post("http://localhost:8081/application/applyForInquiry", application);
        await axios.post("https://www.jongmm.com/application/applyForInquiry", application);
        alert("신청되었습니다.");
        closeModal;
    }

    return (
        <div id="Inquiry">
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="inquiryModal"
                style={{
                    overlay: {
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        height: '100%',
                        width: '100%'
                    },
                    content: {
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        border: '0px solid red',
                        borderRadius: '20px',
                        backgroundColor: 'white',
                        width: '35vw',
                        height: '60%',
                        placeSelf: 'center',
                        zIndex: 2,
                        overflow: 'scroll'
                    }
                }}
            >
                <InquiryFormModal closeModal={closeModal} onInputChange={onInputChange} onSubmit={onSubmit} />
            </Modal>
        </div>
    );
}

export default Inquiry;