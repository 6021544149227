import React, { useEffect, useState } from 'react'
import AdminAuth from './AdminAuth';
import axios from 'axios';
import CheckBox from '../../components/CheckBox';
import SearchBar from '../../components/SearchBar';
import SortDropdown from '../../components/SortDropdown';
import PerformanceEdit from './PerformanceEdit';

const PerformanceList = () => {
    const { handleLogout } = AdminAuth();

    const [performanceApplication, setPerformanceApplication] = useState([]);
    const [sortOrder, setSortOrder] = useState('latest');
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [editItem, setEditItem] = useState(null);

    useEffect(() => {
        // axios.get('http://localhost:8081/performance/getAllPerformanceApplication')
        axios.get('https://www.jongmm.com/performance/getAllPerformanceApplication')
            .then(response => {
                const sortedApplications = response.data.sort((a,b) => {
                    if(sortOrder == 'latest') {
                        return new Date(b.upload_date) - new Date(a.upload_date);
                    } else {
                        return new Date(a.upload_date) - new Date(b.upload_date);
                    }
                });
                setPerformanceApplication(sortedApplications);
            })
            .catch(error => {
                console.log("데이터를 불러올 수 없습니다."), error;
            });
    }, [sortOrder]);

    const handleDelete = (id) => {
        if (window.confirm("정말 삭제하겠습니까?")) {
            // axios.delete(`http://localhost:8081/admin/deletePerformanceApplication/${id}`)
            axios.delete(`https://www.jongmm.com/admin/deletePerformanceApplication/${id}`)
                .then(response => {
                    setPerformanceApplication(prevApplications =>
                        prevApplications.filter(application => application.id !== id)
                    );
                })
                .catch(error => {
                    console.error("항목을 삭제할 수 없습니다! 개발자에게 문의하세요.", error);
                });
        }
    };

    const handleSelectedItems = (selected) => {
        setSelectedItems(selected);
    };

    const handleSortOrderChange = (event) => {
        setSortOrder(event.target.value);
    };

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleEdit = (item) => {
        setEditItem(item);
    };

    const handleCloseEdit = () => {
        setEditItem(null);
    };

    const filteredApplication = performanceApplication.filter(application =>
        (selectedItems.length === 0 || selectedItems.includes(application.workCategory)) &&
        (searchTerm === '' ||
            application.title.includes(searchTerm) ||
            application.region.includes(searchTerm)
        )
    );
    
    return (
        <div>
            <h1>실적 게시판 페이지입니다.</h1>
            <CheckBox handleSelectedItems={handleSelectedItems} data = {[
                { id: 1, title: '도장' },
                { id: 2, title: '방수' },
                { id: 3, title: '기타' }
            ]} />
            <div id='controlPanel'>
                <SortDropdown sortOrder={sortOrder} handleSortOrderChange={handleSortOrderChange} />
                <SearchBar searchTerm={searchTerm} handleSearchTermChange={handleSearchTermChange} />
            </div>
            <div id='itemList'>
                <ul>
                    {filteredApplication.map(application => (
                        <div key={application.id} className='itemBox'>
                            <div id='shadowBox'></div>
                            <img src={`/uploads/${application.main_image}`} alt={application.title} />
                            <div id='pBox'>  
                                <p>{application.workCategory === 1 ? '도장' :
                                    application.workCategory === 2 ? '방수' :
                                    '기타'}</p>
                                <p>{application.region}</p>
                                <p>{application.title}</p>
                                <br />
                                <p id='description'>{application.title}</p>
                                <button onClick={() => handleEdit(application)}>수정</button>
                                <button onClick={() => handleDelete(application.id)}>삭제</button>
                            </div>
                        </div>
                    ))}
                </ul>
            </div>
            {editItem && <PerformanceEdit application={editItem} onClose={handleCloseEdit} />}
        </div>
    )
}

export default PerformanceList;