import React, { useState, useEffect } from 'react'

const ImageUploadPreview = ({onImageUpload, initialImages = [] }) => {
    const [images, setImages] = useState(initialImages);
    const [errorMessage, setErrorMessage] = useState('');

    // useEffect(() => {
    //     // 초기 이미지 설정
    //     setImages(initialImages);
    // }, [initialImages]);

    const handleImageChange = (e, index) => {
        const file = e.target.files[0]; // 단일 파일 선택

        if(file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const newImages = [...images];
                newImages[index] = event.target.result;
                setImages(newImages);

                if(newImages.length <= 6 || newImages.length >= 1) {
                    onImageUpload(newImages);
                } else {
                    setErrorMessage('이미지는 최소 1개, 최대 6개 업로드 가능합니다.');
                }
            };
            reader.onerror = (err) => {
                setErrorMessage('이미지 처리 중 에러가 발생했습니다.');
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <div>
            <h1>Multiple Image Upload Preview</h1>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            <div className='input-group'>
                {Array.from({length:6}).map((_,index) => (
                    <div key={index}>
                        <input
                            type='file'
                            accept='image/*'
                            onChange={(e) => handleImageChange(e, index)}
                        />
                        {images[index] && (
                            <div className='image-preview'>
                                <img
                                    // src={images[index]}
                                    src={images[index].startsWith('data:image') ? images[index] : `/uploads/${images[index]}`}
                                    alt={`Preview ${index}`}
                                    style={{maxWidth: '100%', maxHeight: '200px'}}
                                />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImageUploadPreview;