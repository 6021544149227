import React, { useEffect, useState } from 'react'
import Box from "@mui/material/Box";
import Upload from '../../components/Upload';
import Popup from '../../components/Popup';
import defaultImage from '../../asset/image/default_image.png';
import axios from 'axios';
import AdminAuth from './AdminAuth';
import ImageUploadPreview from '../../components/ImageUploadPreview';
import PerformanceModal from '../../components/PerformanceModal';
import Modal from 'react-modal'

const FormBox = ({ onInputChange, onSubmit }) => {
    return (
        <div>
            <form onSubmit={(e) => onSubmit(e)}>
                <div className='radioBox'>
                    <p>작업 내용</p>
                    <input 
                    type='radio' 
                    id='stamp' 
                    name='workCategory' 
                    value='1'
                    onChange={(e) => onInputChange(e)}
                    required
                    />
                    <label htmlFor='stamp'>도장</label>
                    <input 
                    type='radio' 
                    id='waterproof' 
                    name='workCategory' 
                    value='2'
                    onChange={(e) => onInputChange(e)}
                    />
                    <label htmlFor='waterproof'>방수</label>
                    <input 
                    type='radio' 
                    id='workOther' 
                    name='workCategory' 
                    value='3'
                    onChange={(e) => onInputChange(e)}
                    />
                    <label htmlFor='workOther'>기타</label>
                </div>
                <input
                type='text'
                name='region'
                placeholder='지역을 입력하세요.'
                onChange={(e) => onInputChange(e)}
                required
                />
                <input
                type='text'
                name='title'
                placeholder='제목을 입력하세요.'
                onChange={(e) => onInputChange(e)}
                required
                />
                <textarea
                name='detail_texts'
                placeholder='이미지 상세 설명을 입력해주세요 | 이것을 사용해서 구분해주세요.'
                onChange={(e) => onInputChange(e)}
                required
                />
                <input
                type='text'
                name='link'
                placeholder='링크를 입력하세요.'
                onChange={(e) => onInputChange(e)}
                required
                />
                <input type='submit' value={'완료'} />
            </form>
        </div>
    )
}

const PerformanceUpdate = () => {
    const { handleLogout } = AdminAuth();

    const [mainOpen, setMainOpen] = useState(false);
    const [mainImage, setMainImage] = useState(defaultImage);
    const [application, setApplication] = useState({
        main_image: "",
        title: "",
        workCategory: "",
        region: "",
        detail_texts: "",
        detail_images: "",
        upload_date: new Date().toISOString(),
        views: 0,
        link: ""
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCloseMain = () => {
        setMainOpen(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    const onInputChange = (e) => {
        setApplication({...application, [e.target.name]:e.target.value});
    }

    const openPreviewModal = () => {
        console.log('Opening preview modal with application: ', application);
        setIsModalOpen(true);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        console.log('Submitting application: ', application);
        try {
            // await axios.post("http://localhost:8081/admin/performanceUpload", application);
            await axios.post("https://www.jongmm.com/admin/performanceUpload", application);
            alert("업로드 완료");
        } catch (error) {
            console.error('업로드 중 에러 발생: ', error);
        }
    }

    const onImageUpload = (images) => {
        console.log('Images received from ImageUploadPreview: ', images);
        const detailImagesString = images.join('|');

        setApplication({
            ...application,
            detail_images: detailImagesString
        });
    };

    const handleMainImageUpload = (croppedImage) => {
        setMainImage(croppedImage);
        setApplication({ ...application, main_image: croppedImage });
        handleCloseMain();
    }
    return (
        <div>
            <div id='performanceUpdateBox'>
                <h1>시공 사례 업데이트 페이지입니다.</h1>
                <Box
                    sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginLeft: "5%",
                    width: "21%"
                    }}
                >
                    <Box my={2}>
                    <img src={mainImage} alt="cropped" height={400} />
                    </Box>

                    <Upload
                    getUploadedFile={(image) => {
                        setMainOpen(true);
                        setMainImage(image);
                    }}
                    />
                    <Popup
                    open={mainOpen}
                    handleClose={handleCloseMain}
                    image={mainImage}
                    getCroppedFile={(image) => {
                        handleMainImageUpload(image);
                    }}
                    />
                </Box>
                <ImageUploadPreview 
                    onImageUpload={onImageUpload} 
                    initialImages={[]}     //초기 이미지 없음
                />
                <FormBox onInputChange={onInputChange} onSubmit={onSubmit} />

                <button onClick={openPreviewModal}>미리보기</button>
            </div>
            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}
                style={{
                    overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    height: '100%',
                    width: '100%'
                    },
                    content: {
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    border: '0px solid red',
                    backgroundColor: 'white',
                    width: '40%',
                    height: '65%',
                    placeSelf: 'center',
                    zIndex: 2,
                    overflow: 'scroll',
                    boxShadow: '1px 3px 12px #00000029',
                    top: '5vh',
                    inset: '5vh 40px 40px 40px'
                    }
                }}>
                <PerformanceModal show={isModalOpen} onClose={handleCloseModal} application={application} />
            </Modal>
        </div>
    )
}

export default PerformanceUpdate;