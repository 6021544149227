import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import Header from '../layout/Header'
import Nav from '../layout/Nav'
import Footer from '../layout/Footer'

import '../asset/css/Performance.css'

import axios from 'axios';
import SortDropdown from '../components/SortDropdown';
import SearchBar from '../components/SearchBar';
import CheckBox from '../components/CheckBox';

import blog from '../asset/image/blogIcon.png'

const Performance = () => {

    const [performanceApplication, setPerformanceApplication] = useState([]);
    const [sortOrder, setSortOrder] = useState('latest');
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const [selectedApplication, setSelectedApplication] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        // axios.get('http://localhost:8081/performance/getAllPerformanceApplication')
        axios.get('https://www.jongmm.com/performance/getAllPerformanceApplication')
            .then(response => {
                const sortedApplications = response.data.sort((a,b) => {
                    if(sortOrder == 'latest') {
                        return new Date(b.upload_date) - new Date(a.upload_date);
                    } else {
                        return new Date(a.upload_date) - new Date(b.upload_date);
                    }
                });
                setPerformanceApplication(sortedApplications);
            })
            .catch(error => {
                console.log("데이터를 불러올 수 없습니다."), error;
            });
    }, [sortOrder]);

    const filteredApplication = performanceApplication.filter(application =>
        (selectedItems.length === 0 || selectedItems.includes(application.workCategory)) &&
        (searchTerm === '' ||
            application.title.includes(searchTerm) ||
            application.region.includes(searchTerm)
        )
    );
    
    const handleSelectedItems = (selected) => {
        setSelectedItems(selected);
    };

    const handleSortOrderChange = (event) => {
        setSortOrder(event.target.value);
    };

    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleItemClick = (application) => {
        const detailImages = application.detail_images.split('|');
        const detailTexts = application.detail_texts.split('|');

        setSelectedApplication({
            ...application,
            detail_images: detailImages,
            detail_texts: detailTexts
        });

        // setSelectedApplication(application);
        setIsModalOpen(true);

        // axios.post(`http://localhost:8081/performance/incrementViews/${application.id}`)
        axios.post(`https://www.jongmm.com/performance/incrementViews/${application.id}`)
            .then(response => {
                console.log("Views incremented successfully");
            })
            .catch(error => {
                console.error("Error increment views", error);
            });
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedApplication(null);
    }

    return (
        <div id='performance'>
            <Header />
            {/* <p className='pageTitle' id='performanceTitle'><span>Performance</span><br />시공실적</p> */}
            <div id='aboutTitleBox'>
                <p className='pageTitle'><span>Performance</span><br />시공실적</p>
                <p id='line'></p>
            </div>
            <div id='controlPanel'>
                <CheckBox handleSelectedItems={handleSelectedItems} data = {[
                    { id: 1, title: '도장' },
                    { id: 2, title: '방수' },
                    { id: 3, title: '기타' }
                ]} />
                <SortDropdown sortOrder={sortOrder} handleSortOrderChange={handleSortOrderChange} />
                <SearchBar searchTerm={searchTerm} handleSearchTermChange={handleSearchTermChange} />
            </div>
            <div id='performanceBox'>
                <div id='itemList'>
                    <ul>
                        {filteredApplication.map(application => (
                            <div key={application.id} className='itemBox' onClick={() => handleItemClick(application)}>
                                <div id='shadowBox'></div>
                                <img src={`/uploads/${application.main_image}`} alt={application.title} />
                                <div id='pBox'>  
                                    <p>{application.workCategory === 1 ? '도장' :
                                        application.workCategory === 2 ? '방수' :
                                        '기타'}</p>
                                    <p>{application.region}</p>
                                    {/* <p>{application.title}</p> */}
                                    <br />
                                    <p id='description'>{application.title}</p>
                                </div>
                            </div>
                        ))}
                    </ul>
                </div>
            </div>
            <Nav />
            <Modal id='performanceDetailModal' isOpen={isModalOpen} onRequestClose={handleCloseModal}
                style={{
                    overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    height: '100%',
                    width: '100%'
                    },
                    content: {
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    // right: '0',
                    // bottom: '40px',
                    border: '0px solid red',
                    // borderRadius: '45px',
                    // background: '#fff',
                    // overflow: 'auto',
                    // WebkitOverflowScrolling: 'touch',
                    // borderRadius: '4px',
                    // outline: 'none',
                    // padding: '20px'
                    backgroundColor: 'white',
                    width: '40%',
                    height: '65%',
                    placeSelf: 'center',
                    zIndex: 2,
                    overflow: 'scroll',
                    boxShadow: '1px 3px 12px #00000029',
                    top: '5vh',
                    inset: '5vh 40px 40px 40px'
                    }
                }}>
                    <PerformanceModal show={isModalOpen} onClose={handleCloseModal} application={selectedApplication} />
                </Modal>
                <Footer />
        </div>
    )
}

const PerformanceModal = ({ show, onClose, application }) => {
    if(!show || !application) {
        return null;
    }

    const formatDate = (dateString) => {
        const options = {year: 'numeric', month: 'long', day: 'numeric'};
        return new Date(dateString).toLocaleDateString('ko-KR', options);
    };

    const convertNewlinesToBreaks = (text) => {
        return text.split('\n').map((line, index) => (
            <span key={index}>
                {line}
                <br />
            </span>
        ));
    };

    return (
        <div id='performanceModal'>
            <span id="closeBtn" className="material-symbols-outlined" onClick={onClose}>
            close
            </span>
            <p id='dateText'>{formatDate(application.upload_date)}</p>
            <p id='performanceModalTitle'>{application.title}</p>
            <p id='views'>조회수 : {application.views}</p>
            <div id='line'></div>

            {application.detail_images.map((image, index) => (
                <div key={index} className='detailBox'>
                    <img className='detailImage' src={`/uploads/${image}`} alt={`상세 이미지${index + 1}`} />
                    <p className='detailText'>{convertNewlinesToBreaks(application.detail_texts[index] || '')}</p>
                </div>
            ))}
        </div>
    )
}

export default Performance;