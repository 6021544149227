import React, { useState, useEffect } from 'react'
import Modal from 'react-modal';
import '../asset/css/Recruitment.css'
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ApplicationFormModal = ({closeModal, onInputChange, onSubmit}) => (
    <div id='applicationForm'>
        <span id="closeBtn" className="material-symbols-outlined" onClick={closeModal}>
        close
        </span>
        <div id='formBox'>
            <h1 id='modalTitle'>지원서</h1>
            <form method='POST' onSubmit={(e) => onSubmit(e)}>
                <div className='inputTextBox'>
                    <span class="material-symbols-outlined">id_card</span>
                    <input 
                    type={'text'}
                    className='inputText' 
                    name='name' 
                    placeholder='이름을 입력하세요.' 
                    required 
                    onChange={(e) => onInputChange(e)}
                    />
                </div>
                <div className='inputTextBox'>
                    <span class="material-symbols-outlined">call</span>
                    <input 
                    type={'text'} 
                    className='inputText' 
                    name='contact' 
                    placeholder='전화번호를 입력하세요.' 
                    required
                    onChange={(e) => onInputChange(e)}
                    />
                </div>
                <input type='submit' value={'완료'}></input>
                <div className='radioBox'>
                    <p>경력</p>
                    <input 
                    type='radio' 
                    id='none' 
                    name='experience' 
                    value='1'
                    onChange={(e) => onInputChange(e)} 
                    required
                    />
                    <label for='none'>없음</label>
                    <input 
                    type='radio' 
                    id='less_than_2years' 
                    name='experience' 
                    value='2'
                    onChange={(e) => onInputChange(e)} 
                    required
                    />
                    <label for='less_than_2years'>2년 미만</label>
                    <input 
                    type='radio' 
                    id='2years_or_more' 
                    name='experience' 
                    value='3'
                    onChange={(e) => onInputChange(e)} 
                    required
                    />
                    <label for='2years_or_more'>2년 이상</label>
                    <input 
                    type='radio' 
                    id='5years_or_more' 
                    name='experience' 
                    value='4'
                    onChange={(e) => onInputChange(e)} 
                    required
                    />
                    <label for='5years_or_more'>5년 이상</label>
                </div>
                <p>소개</p>
                <textarea 
                name='introduction' 
                cols={50} 
                rows={15} 
                placeholder='자기소개'
                required 
                onChange={(e) => onInputChange(e)}
                />
            </form>
        </div>
    </div>
);

const Recruitment = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    let navigate = useNavigate();

    const [jobPostings, setJobPostings] = useState([]);

    const [application, setApplication] = useState({
        name: "",
        contact: "",
        experience: "",
        introduction: ""
    })

    const{name, contact, experience, introduction} = application

    const onInputChange=(e)=>{
        setApplication({...application, [e.target.name]:e.target.value});
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        // await axios.post("http://localhost:8081/application/applyForRecruitment", application);
        await axios.post("https://www.jongmm.com/application/applyForRecruitment", application);
        alert("신청되었습니다.");
        setModalIsOpen(false);
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
        // axios.get('http://localhost:8081/admin/getAllJobPostings')
        axios.get('https://www.jongmm.com/admin/getAllJobPostings')
            .then(response => {
                setJobPostings(response.data);
            })
            .catch(error => {
                console.error('채용공고를 불러올 수 없습니다!'), error;
            });
    }, []);

    return (
        <div id="Recruitment">
            <div id='jobPostingBox'>
                {jobPostings.map(posting => (
                    <ul id='jobPosting'>
                        <li id='jobPostingState'>{posting.status === 1 ? '채용중' : '채용 완료'}</li>
                        <li id='jobPostingTitle'>{posting.title}</li>
                        <li id='jobPostingPeriod'>{posting.start_date}~{posting.end_date}</li>
                        {posting.status === 1 ? (
                                <button id="applicationFormModalBtn" onClick={openModal}>지원하기</button>
                            ) : (
                                <button undefined>채용 종료된 공고입니다.</button>
                            )}
                    </ul>
                ))}
                <Modal isOpen={modalIsOpen} onRequestClose={closeModal}
                style={{
                    overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    height: '100%',
                    width: '100%'
                    },
                    content: {
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    border: '0px solid red',
                    borderRadius: '20px',
                    // background: '#fff',
                    // overflow: 'auto',
                    // WebkitOverflowScrolling: 'touch',
                    // outline: 'none',
                    // padding: '20px'
                    backgroundColor: 'white',
                    width: '500px',
                    height: '60%',
                    placeSelf: 'center',
                    zIndex: 2,
                    overflow: 'scroll'
                    }
                }}>
                    <ApplicationFormModal closeModal={closeModal} onInputChange={onInputChange} onSubmit={onSubmit} />
                </Modal>
            </div>
        </div>
    )
}

export default Recruitment;