import React from 'react';

const EmailCollectionBanModal = ({ show, onClose }) => {
    if (!show) {
        return null;
    }
    return (
        <div id='emailCollectionBanModal'>
            <span id="closeBtn" className="material-symbols-outlined" onClick={onClose}>
                close
            </span>
            <p>
            이메일 무단수집거부
            <br />
            <br />
            <br />
            본 웹사이트에 게시된 이메일 주소가 전자우편 수집 프로그램이나 그 밖의 기술적 장치를 이용하여 무단으로 수집되는 것을 거부합니다. 이를 위반할 경우 "정보통신망 이용촉진 및 정보보호 등에 관한 법률"에 의해 형사 처벌될 수 있음을 유념하시기 바랍니다.
            <br />
            <br />
            [관련 법령]
            - 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제50조
            </p>
        </div>
    );
}

export default EmailCollectionBanModal;