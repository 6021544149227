import React, { useEffect, useState } from 'react'
import AdminAuth from './AdminAuth';
import axios from 'axios';
import CheckBox from '../../components/CheckBox';
import SearchBar from '../../components/SearchBar';
import SortDropdown from '../../components/SortDropdown';

const JobApplications = () => {
    const { handleLogout } = AdminAuth();

    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [jobApplications, setJobApplications] = useState([]);
    const [sortOrder, setSortOreder] = useState('latest');  // 최신순 기본 설정
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // axios.get('http://localhost:8081/admin/getAllJobApplications')
        axios.get('https://www.jongmm.com/admin/getAllJobApplications')
            .then(response => {
                const sortedApplications = response.data.sort((a,b) => {
                    if (sortOrder === 'latest') {
                        return new Date(b.applied_at) - new Date(a.applied_at);
                    } else {
                        return new Date(a.applied_at) - new Date(b.applied_at);
                    }
                });
                setJobApplications(sortedApplications);
            })
            .catch(error => {
                console.error("채용 신청서를 불러올 수 없습니다! 개발자에게 문의하세요."), error;
            });
    }, [sortOrder]);

    // 선택된 아이템을 설정하는 함수
    const handleSelectedItems = (selected) => {
        setSelectedItems(selected);
    };

    const handleSelectedStatus = (selected) => {
        setSelectedStatus(selected);
    };

    // 선택된 정렬 순서를 설정하는 함수
    const handleSortOrderChange = (event) => {
        setSortOreder(event.target.value);
    };

    // 검색어를 설정하는 함수
    const handleSearchTermChange = (event) => {
        setSearchTerm(event.target.value);
    };
    
    const filteredApplications = jobApplications.filter(application =>
        (selectedItems.length === 0 || selectedItems.includes(application.experience)) &&
        (selectedStatus.length === 0 || selectedStatus.includes(application.status)) &&
        (searchTerm === '' ||
            application.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            application.contact.includes(searchTerm) ||
            application.job_title.includes(searchTerm) ||
            application.introduction.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    const toggleStatus = (id, currentStatus) => {
        const newStatus = currentStatus === 1 ? 0 : 1;
        // axios.post('http://localhost:8081/admin/toggleJobApplicationStatus', {id, status: newStatus})
        axios.post('https://www.jongmm.com/admin/toggleJobApplicationStatus', {id, status: newStatus})
            .then(response => {
                setJobApplications(prevApplications =>
                    prevApplications.map(application =>
                        application.id === id ? { ...application, status: newStatus } : application
                    )
                );
            })
            .catch(error => {
                console.error("상태를 변경할 수 없습니다! 개발자에게 문의하세요.", error);
            });
    };
    
    return (
        <div>
            <h1>채용 신청 페이지입니다.</h1>
            <SortDropdown sortOrder={sortOrder} handleSortOrderChange={handleSortOrderChange} />
            <SearchBar searchTerm={searchTerm} handleSearchTermChange={handleSearchTermChange} />
            <CheckBox handleSelectedItems={handleSelectedItems} data = {[
                { id: 1, title: '없음' },
                { id: 2, title: '2년 미만' },
                { id: 3, title: '2년 이상' },
                { id: 4, title: '5년 이상' }
            ]} />
            <CheckBox handleSelectedItems={handleSelectedStatus} data = {[
                { id: 1, title: '채용' },
                { id: 0, title: '채용 취소' }
            ]} />
            <div>
                <ul>
                    {filteredApplications.map(application => (
                        <li key={application.id}>
                            <p>신청 공고 : {application.job_title}</p>
                            <p>신청자 : {application.name}</p>
                            <p>연락처 : {application.contact}</p>
                            <p>경력 : {application.experienceLevel}</p>
                            <p>소개 : {application.introduction}</p>
                            <p>신청 시간 : {application.applied_at}</p>
                            <button onClick={() => toggleStatus(application.id, application.status)}>{application.status === 1 ? '채용' : '채용 취소'}</button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default JobApplications;