import React, { useEffect, useState } from 'react';
import '../asset/css/CheckBox.css'

const CheckBox = ({ handleSelectedItems, data }) => {

    // 체크된 아이템을 담을 배열
    const [checkItems, setCheckItems] = useState([]);

    // 체크박스 단일 선택
    const handleSingleCheck = (checked, id) => {
        if (checked) {
            // 단일 선택 시 체크된 아이템을 배열에 추가
            setCheckItems(prev => [...prev, id]);
        } else {
            // 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    // 전체 선택
    const handleAllCheck = (checked) => {
        if (checked) {
            // 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
            const idArray = data.map((el) => el.id);
            setCheckItems(idArray);
        } else {
            // 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
            setCheckItems([]);
        }
    };

    useEffect(() => {
        handleSelectedItems(checkItems);
    }, [checkItems, handleSelectedItems]);

    return (
        <div className='checkbox-container'>
            <div className='checkbox-items'>
                <div className='checkbox-item'>
                    <input
                        type='checkbox'
                        name='select-all'
                        onChange={(e) => handleAllCheck(e.target.checked)}
                        checked={checkItems.length === data.length}
                    />
                    <span>전체</span>
                </div>
                {data.map((item, key) => (
                    <div className='checkbox-item' key={key}>
                        <input
                            type='checkbox'
                            name={`select-${item.id}`}
                            onChange={(e) => handleSingleCheck(e.target.checked, item.id)}
                            checked={checkItems.includes(item.id)}
                        />
                        <span>{item.title}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CheckBox;