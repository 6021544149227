import mainImg1 from '../image/mainImg1.png'
import mainImg2 from '../image/mainImg2.png'
import mainImg3 from '../image/mainImg3.png'

const imageData = [
    {
        label: "mainImg 1",
        alt: "mainImage1",
        url: mainImg1,
    },

    {
        label: "mainImg 2",
        alt: "mainImage2",
        url: mainImg2,
    },

    {
        label: "mainImg 3",
        alt: "mainImage3",
        url: mainImg3,
    },
];

export default imageData;