import React, { useState, useEffect } from 'react'
import '../asset/css/Home.css'
import '../asset/css/Modal.css'
import Recruitment from './Recruitment';
import Inquiry from './Inquiry';
import Nav from '../layout/Nav'

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import logo from '../asset/image/jmcIcon.png';
import logoW from '../asset/image/jmcIconW.png';

import imageData from '../asset/data/carousel';

const renderSlides = imageData.map(image => (
    <div key={image.alt}>
        <img src={image.url} alt={image.alt} />
    </div>
));

const Home = () => {
    const [currentIndex, setCurrentIndex] = useState();
    const [isInquiryModalOpen, setInquiryModalOpen] = useState(false);

    const handleChange = (index) => {
        setCurrentIndex(index);
    };

    const openInquiryModal = () => {
        setInquiryModalOpen(true);
    };

    const closeInquiryModal = () => {
        setInquiryModalOpen(false);
    };

    const [imageHomeSrc, setImageHomeSrc] = useState(logo);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 500) {
                setImageHomeSrc(logoW);
            } else {
                setImageHomeSrc(logo);
            }
        };

        // 페이지 로드 시 한 번 실행
        handleResize();

        // 윈도우 크기 변경 감지
        window.addEventListener('resize', handleResize);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    return (
        <div className='flex justify-center items-center py-5 px-3'>
            <img id='homeLogo' src={imageHomeSrc}></img>
            <Carousel
                showArrows={false}
                autoPlay={true}
                infiniteLoop={true}
                showThumbs={false}
                selectedItem={imageData[currentIndex]}
                onChange={handleChange}
                className="w-[400px] lg:hidden">
                {renderSlides}
            </Carousel>
            <div id='mainText'>
                <p>대한민국 시트의 기준, 방수의 혁신.<br/>Become the standard.</p>
            </div>
            <div id='homeNav' style={{display: 'none'}}>
                <Nav />
            </div>
            <div id='menuListHeader'>
                <ul>
                    <li className='menu' id='about'><a href='about'>회사소개</a></li>
                    <div className='line'></div>
                    <li className='menu'><a href='product'>공법소개</a></li>
                    <div className='line'></div>
                    <li className='menu' id='product'>시공분야</li>
                    <div id='productList'>
                        <li className='productMenu'>
                            <a href='rooftopWaterproofing'>옥상 시트방수</a>
                            <div className='line'></div>
                        </li>
                        <li className='productMenu'>
                            <a href='commercialKitchenWaterproofing'>상업용 주방 시트방수</a>
                            <div className='line'></div>
                        </li>
                        <li className='productMenu'>
                            <a href='poolWaterproofing'>수영장 시트방수</a>
                        </li>
                    </div>
                    <div className='line'></div>
                    <li className='menu' id='performance'><a href='performance'>시공실적</a></li>
                    <div className='line'></div>
                    <li className='menu' id='inquiry' onClick={openInquiryModal}>견적문의</li>
                    {/* <li className='menu' id='recruitment'>채용공고</li> */}
                    <Inquiry modalIsOpen={isInquiryModalOpen} closeModal={closeInquiryModal}/>
                    <Recruitment />
                </ul>
            </div>
        </div>
    )
}

export default Home;