import React from 'react'
import '../../asset/css/RooftopWaterproofing.css'
import Header from '../../layout/Header'
import Nav from '../../layout/Nav'
import Footer from '../../layout/Footer'
import HeaderImage from '../../asset/image/RooftopWaterproofingHeader.png'
import pvcImg1 from '../../asset/image/RudecsSheet.png'
import pvcImg2 from '../../asset/image/RudecsSheet2.png'
import RudecsPVCSheet from '../../asset/image/RudecsPVCSheet2.png'

import slaveTypeImg1 from '../../asset/image/slaveType/slaveType1.png'
import slaveTypeImg2 from '../../asset/image/slaveType/slaveType2.png'
import slaveTypeImg3 from '../../asset/image/slaveType/slaveType3.png'
import slaveTypeImg4 from '../../asset/image/slaveType/slaveType4.png'

import spanRoofTypeImg1 from '../../asset/image/spanRoofType/spanRoofType1.png'
import spanRoofTypeImg2 from '../../asset/image/spanRoofType/spanRoofType2.png'
import spanRoofTypeImg3 from '../../asset/image/spanRoofType/spanRoofType3.png'
import spanRoofTypeImg4 from '../../asset/image/spanRoofType/spanRoofType4.png'

import drainTypeImg1 from '../../asset/image/drainType/drainType1.png'
import drainTypeImg2 from '../../asset/image/drainType/drainType2.png'
import drainTypeImg3 from '../../asset/image/drainType/drainType3.png'
import drainTypeImg4 from '../../asset/image/drainType/drainType4.png'

const RooftopWaterproofing = () => {
    return (
        <div id='rooftopWaterproofing'>
            <Nav />
            <Header />
            <div id='header'>
                <img id='headerImage' src={HeaderImage} />
            </div>
            <div id='aboutTitleBox'>
                <p className='pageTitle'><span>Waterproof of roof</span><br />옥상 시트방수</p>
                <p id='line'></p>
            </div>
            <div className='productBox'>
                <div id='textBox'>
                    <p id='title'>루덱스 PVC시트방수</p>
                    <p id='tag'>#슬라브 &nbsp; #지붕 &nbsp; #배수로 &nbsp; #테라스</p>
                    <p id='detailText'>PVC시트방수는 액체 방수재를 바르는 형식이 아닌, 시트를 사용하여 방수층을 형성하는 건식공법입니다. <br /> 햇빛에 강해 변형없이 오랜 유지력과 방수 기능으로 건물을 지킬 수 있습니다.</p>
                </div>
                <div id='imgBox'>
                    <img src={pvcImg1} />
                    <img src={pvcImg2} id='rightImg' />
                </div>
                <img src={RudecsPVCSheet} id='rudecsPVCSheetImg' />
                <div id='pvcDetailTextBox'>
                    <div id='detailTextBox1' className='detailTextBox'>
                        <p className='detailTitle'>햇빛에 강한 내구성</p>
                        <div id='line'></div>
                        <p className='detailText'>자외선차단(UV코팅) 처리로 외부환경에서도 안정적으로 기능을 유지합니다.</p>
                    </div>
                    <div id='detailTextBox2' className='detailTextBox'>
                        <p className='detailTitle'>견고한 방수층</p>
                        <div id='line'></div>
                        <p className='detailText'>폴리에스터 직물층에 친환경 졸을 코팅하여 PVC층간 견고한 방수층을 형성합니다. </p>
                    </div>
                    <div id='detailTextBox3' className='detailTextBox'>
                        <p className='detailTitle'>시공 용이성</p>
                        <div id='line'></div>
                        <p className='detailText'>저온에서도 쉽게 깨지지 않고, 가소화 효율을 높여 유연함을 유지합니다. </p>
                    </div>
                </div>
            </div>
            <div id='constructionExamplesBox'>
                <div id='slaveTypeBox' className='examplesBox'>
                    <p id='title'>슬라브형 옥상 시공사례</p>
                    <div id='examplesImgBox'>
                        <div className='examplesBox1'>
                            <img src={slaveTypeImg1} />
                            <p><span>단지명</span> 경기 신원당삼보아파트</p>
                            <p><span>준공일</span> 2024. 07. 12</p>
                        </div>
                        <div className='examplesBox1'>
                            <img src={slaveTypeImg2} />
                            <p><span>단지명</span> 인천 진주4단지아파트</p>
                            <p><span>준공일</span> 2024. 05. 17</p>
                        </div>
                        <div className='examplesBox1'>
                            <img src={slaveTypeImg3} />
                            <p><span>단지명</span> 경남 한마음타운아파트</p>
                            <p><span>준공일</span> 2022. 05. 31</p>
                        </div>
                        <div className='examplesBox1' id='examples4Box'>
                            <img src={slaveTypeImg4} />
                            <p><span>단지명</span> 경기 대우삼호아파트</p>
                            <p><span>준공일</span> 2020. 06. 19</p>
                        </div>
                    </div>
                </div>
                <div id='spanRoofTypeBox' className='examplesBox'>
                    <p id='title'>박공지붕 시공사례</p>
                    <div id='examplesImgBox'>
                        <div className='examplesBox1'>
                            <img src={spanRoofTypeImg1} />
                            <p><span>단지명</span> 경북 신한토탈아파트</p>
                            <p><span>준공일</span> 2020. 12. 25</p>
                        </div>
                        <div className='examplesBox1'>
                            <img src={spanRoofTypeImg2} />
                            <p><span>단지명</span> 경기 청솔마을성원아파트</p>
                            <p><span>준공일</span> 2018. 10. 16</p>
                        </div>
                        <div className='examplesBox1'>
                            <img src={spanRoofTypeImg3} />
                            <p><span>단지명</span> 경기 탑대우아파트</p>
                            <p><span>준공일</span> 2017. 11. 17</p>
                        </div>
                        <div className='examplesBox1' id='examples4Box'>
                            <img src={spanRoofTypeImg4} />
                            <p><span>단지명</span> 강원 초록지붕6차아파트</p>
                            <p><span>준공일</span> 2017. 01. 19</p>
                        </div>
                    </div>
                </div>
                <div id='drainTypeBox' className='examplesBox'>
                    <p id='title'>배수로 시공사례</p>
                    <div id='examplesImgBox'>
                        <div className='examplesBox1'>
                            <img src={drainTypeImg1} />
                            <p><span>단지명</span> 경기 사랑마을동신아파트</p>
                            <p><span>준공일</span> 2020. 08. 21</p>
                        </div>
                        <div className='examplesBox1'>
                            <img src={drainTypeImg2} />
                            <p><span>단지명</span> 경기 모닝사이드아파트</p>
                            <p><span>준공일</span> 2019. 09. 19</p>
                        </div>
                        <div className='examplesBox1'>
                            <img src={drainTypeImg3} />
                            <p><span>단지명</span> 경기 한양수리아파트</p>
                            <p><span>준공일</span> 2018. 09. 04</p>
                        </div>
                        <div className='examplesBox1' id='examples4Box'>
                            <img src={drainTypeImg4} />
                            <p><span>단지명</span> 경기 쌍용스윗닷홈아파트</p>
                            <p><span>준공일</span> 2018. 05. 11</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default RooftopWaterproofing;