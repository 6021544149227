import React, { useEffect, useState } from "react";
import axios from "axios";
import defaultImage from '../../asset/image/default_image.png';
import ImageUploadPreview from '../../components/ImageUploadPreview';
import Box from '@mui/material/Box';
import Popup from '../../components/Popup';
import Upload from '../../components/Upload';
import AdminAuth from './AdminAuth';
import Modal from 'react-modal'
import PerformanceModal from '../../components/PerformanceModal';


const PerformanceEdit = ({ application, onClose }) => {
    const { handleLogout } = AdminAuth();
    
    const [mainImage, setMainImage] = useState(defaultImage);
    const [mainOpen, setMainOpen] = useState(false);
    const [formData, setFormData] = useState(application);
    const [isModalOpen, setIsModalOpen] = useState(false);
    
    useEffect(() => {
        setFormData(application);
        setMainImage(application.main_image);
    }, [application]);

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleImageUpload = (images) => {
        const detailImagesString = images.join('|');
        setFormData({
            ...formData,
            detail_images: detailImagesString
        });
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    const handleMainImageUpload = (croppedImage) => {
        setMainImage(croppedImage);
        setFormData({ ...formData, main_image: croppedImage });
        handleCloseMain();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // await axios.post(`http://localhost:8081/admin/updatePerformanceApplication/${application.id}`, formData);
            await axios.post(`https://www.jongmm.com/admin/updatePerformanceApplication/${application.id}`, formData);
            alert("수정 완료");
            onClose();
        } catch (error) {
            console.error('수정 중 에러 발생: ', error);
        }
    };

    const handleCloseMain = () => {
        setMainOpen(false);
    };

    return (
        <div>
            <h1>게시글 수정</h1>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "5%", width: "21%" }}>
                <Box my={2}>
                <img 
                    // src={mainImage}
                    src={mainImage.startsWith('data:image') ? mainImage : `/uploads/${mainImage}`} 
                    alt="메인 이미지를 업로드하세요."
                    height={400}
                />
                </Box>
                <Upload getUploadedFile={(image) => {
                    setMainOpen(true);
                    setMainImage(image);
                }} />
                <Popup open={mainOpen} handleClose={handleCloseMain} image={mainImage} getCroppedFile={handleMainImageUpload} />
            </Box>
            <ImageUploadPreview 
                onImageUpload={handleImageUpload} 
                initialImages={application.detail_images ? application.detail_images.split('|') : []}
            />
            <form onSubmit={handleSubmit}>
                <div className='radioBox'>
                    <p>작업 내용</p>
                    <input type='radio' id='stamp' name='workCategory' value='1' checked={formData.workCategory === '1'} onChange={(e) => handleInputChange(e)} required />
                    <label htmlFor='stamp'>도장</label>
                    <input type='radio' id='waterproof' name='workCategory' value='2' checked={formData.workCategory === '2'} onChange={(e) => handleInputChange(e)} />
                    <label htmlFor='waterproof'>방수</label>
                    <input type='radio' id='workOther' name='workCategory' value='3' checked={formData.workCategory === '3'} onChange={(e) => handleInputChange(e)} />
                    <label htmlFor='workOther'>기타</label>
                </div>
                <input type='text' name='region' value={formData.region} placeholder='지역을 입력하세요.' onChange={(e) => handleInputChange(e)} required />
                <input type='text' name='title' value={formData.title} placeholder='제목을 입력하세요.' onChange={(e) => handleInputChange(e)} required />
                <textarea name='detail_texts' value={formData.detail_texts} placeholder='이미지 상세 설명을 입력해주세요 | 이것을 사용해서 구분해주세요.' onChange={(e) => handleInputChange(e)} required />
                <input type='submit' value='수정 완료' />
                <button type='button' onClick={onClose}>취소</button>
            </form>
            <button onClick={() => setIsModalOpen(true)}>미리보기</button>
            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}
                style={{
                    overlay: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    height: '100%',
                    width: '100%'
                    },
                    content: {
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    border: '0px solid red',
                    backgroundColor: 'white',
                    width: '40%',
                    height: '65%',
                    placeSelf: 'center',
                    zIndex: 2,
                    overflow: 'scroll',
                    boxShadow: '1px 3px 12px #00000029',
                    top: '5vh',
                    inset: '5vh 40px 40px 40px'
                    }
                }}>
                <PerformanceModal show={isModalOpen} onClose={handleCloseModal} application={application} />
            </Modal>
        </div>
    )
}

export default PerformanceEdit;