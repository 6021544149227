import React from 'react'
import Header from '../layout/Header'
import Nav from '../layout/Nav'
import '../asset/css/About.css'
import Footer from '../layout/Footer'
import HeaderImage from '../asset/image/aboutHeader.png'
import FieldImage from '../asset/image/사업분야.png'
import FooterImage from '../asset/image/aboutFooter.png'
import Icon1 from '../asset/image/aboutIcon1.png'
import Icon2 from '../asset/image/aboutIcon2.png'
import Icon3 from '../asset/image/aboutIcon3.png'

const About = () => {

    return (
        <div>
            <Header />
            <Nav />
            <div id='header'>
                <img id='headerImage' src={HeaderImage} />
            </div>
            <div id='aboutTitleBox'>
                <p className='pageTitle'><span>Our business</span><br />회사소개</p>
                <p id='line'></p>
            </div>
            <div id='aboutList'>
                <div className='aboutBox'>
                    <div id='icon_titleBox'>
                        <img src={Icon1}></img>
                        <p className='boxTitle'><span>1</span> 선두주자</p>
                    </div>
                    <p className='aboutDetailText'>새로운 공법 적용과 지속적인 연구로 올바른 방수시장을 위해 나아갑니다.</p>
                </div>
                <div id='verticalLine'></div>
                <div className='aboutBox'>
                    <div id='icon_titleBox'>
                        <img src={Icon2}></img>
                        <p className='boxTitle'><span>220+</span> 아파트</p>
                    </div>
                    <p className='aboutDetailText'>시공 기술과 노하우를 믿고 맡겨주신 수많은 고객분들과 함께 하고 있습니다.</p>
                </div>
                <div id='verticalLine'></div>
                <div className='aboutBox'>
                    <div id='icon_titleBox'>
                        <img src={Icon3}></img>
                        <p className='boxTitle'><span>1997</span> since</p>
                    </div>
                    <p className='aboutDetailText'>설립 이후 25년동안 꾸준히 건물을 지키기 위한 솔루션을 제공하고 있습니다.</p>
                </div>
            </div>
            <div id='aboutTitleBox'>
                <p className='pageTitle' id='fieldText'><span>Business line</span><br />사업분야</p>
                <p id='line'></p>
            </div>
            <div id='imageBox'>
                <img id='fieldImage' src={FieldImage} />
            </div>
            <div id='footer'>
                <p id='footerImageText'>대한민국 방수의<br />새로운 기준이 되다.</p>
                <img id='footerImage' src={FooterImage} />
                <Footer />
            </div>
        </div>
    )
}

export default About;