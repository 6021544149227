import React, { useState, useEffect } from 'react'
import logo from '../asset/image/jmcIcon.png';
import scrolledLogo from '../asset/image/jmcIconW.png';
import '../asset/css/Header.css'
import Inquiry from '../pages/Inquiry';
import Down from '../asset/image/arrowDown.png'
import Up from '../asset/image/arrowUp.png'

const Header = () => {
    const [isInquiryModalOpen, setInquiryModalOpen] = useState(false);
    const [logoSrc, setLogoSrc] = useState(logo);

    const openInquiryModal = () => {
        setInquiryModalOpen(true);
    };

    const closeInquiryModal = () => {
        setInquiryModalOpen(false);
    };

    const [backgroundColor, setBackgroundColor] = useState('white');

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setBackgroundColor('#4D5053');
                setLogoSrc(scrolledLogo);
            } else {
                setBackgroundColor('white');
                setLogoSrc(logo);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div id='headerBox' style={{ backgroundColor }}>
            <div id='goHome'><a href='/'><img src={logoSrc} /><p>주식회사 종명건설</p></a></div>
            <ul>
                <li><a href='about'>회사소개</a></li>
                <li><a href='product'>공법소개</a></li>
                <li id='product'>시공분야<img id='arrowDown' src={Down} /><img id='arrowUp' src={Up} /></li>
                    <div id='productList'>
                        <li className='productMenu'>
                            <a id='productMenuLink' href='rooftopWaterproofing'>옥상 시트방수</a>
                        </li>
                        <li className='productMenu'>
                            <a id='productMenuLink' href='commercialKitchenWaterproofing'>상업용 주방 시트방수</a>
                        </li>
                        <li className='productMenu'>
                            <a id='productMenuLink' href='poolWaterproofing'>수영장 시트방수</a>
                        </li>
                    </div>
                <li><a href='performance'>시공실적</a></li>
                <li id='inquiry' onClick={openInquiryModal}>견적문의</li>
                <Inquiry modalIsOpen={isInquiryModalOpen} closeModal={closeInquiryModal}/>
            </ul>
        </div>
    )
}

export default Header;