import axios from 'axios';
import React, { useEffect, useState } from 'react'
import '../../asset/css/JobPostingUpdate.css'
import AdminAuth from './AdminAuth';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const EditBox = ({ posting }) => {
    const [dateRange, setDateRange] = useState([new Date(posting.start_date), new Date(posting.end_date)]);
    const [startDate, endDate] = dateRange;

    const [application, setApplication] = useState({
        title: posting.title,
        start_date: posting.start_date,
        end_date: posting.end_date
    });

    const{title} = application

    const onInputChange = (e) => {
        setApplication({...application, [e.target.name]:e.target.value});
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        // await axios.post("http://localhost:8081/admin/jobPostingEdit", application);
        await axios.post("https://www.jongmm.com/admin/jobPostingEdit", application);
        alert("수정이 완료되었습니다.");
    }

    useEffect(() => {
        setApplication({
            ...application,
            start_date: startDate ? startDate.toISOString().split('T')[0] : '',
            end_date: endDate ? endDate.toISOString().split('T')[0] : ''
        });
    }, [startDate, endDate]);

    return (
        <div id='editBox'>
            <form method='POST' onSubmit={(e) => onSubmit(e)}>
                <DatePicker
                dateFormat="yyyy-MM-dd"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                    setDateRange(update);
                }}
                isClearable={true}
                showIcon
                />
                <input 
                id='titleEditBox' 
                type='text' 
                name='title' 
                placeholder='제목을 입력하세요.' 
                onChange={(e) => onInputChange(e)}
                required
                />
                <input type='submit' value={'수정'}></input>
            </form>
        </div>
    );
};

const JobPostingUpdate = () => {
    const { handleLogout } = AdminAuth();

    const [jobPostings, setJobPostings] = useState([]);
    const [editBoxVisible, setEditBoxVisible] = useState(false);
    const [statusUpdateVisible, setStatusUpdateVisble] = useState(false);
    const [selectedPosting, setSelectedPosting] = useState(null);

    useEffect(() => {
        // axios.get('http://localhost:8081/admin/getAllJobPostings')
        axios.get('https://www.jongmm.com/admin/getAllJobPostings')
            .then(response => {
                setJobPostings(response.data);
            })
            .catch(error => {
                console.error('채용공고를 불러올 수 없습니다! 개발자에게 문의하세요.'), error;
            });
    }, []);

    const handleEditClick = (posting) => {
        setSelectedPosting(posting);
        setEditBoxVisible(prev => !prev);
    }
    
    const StatusUpdateBox = ({ onConfirm, onCancel }) => (
        <div id='statusUpdateBox'>
            <p>상태를 변경하겠습니까?</p>
            <button onClick={onConfirm}>확인</button>
            <button onClick={onCancel}>취소</button>
        </div>
    )

    const handleStatusClick = (posting) => {
        setSelectedPosting(posting);
        setStatusUpdateVisble(prev => !prev);
    }

    const handleConfirmStatus = async () => {
        // await axios.post('http://localhost:8081/admin/jobPostingStatusUpdate');
        await axios.post('https://www.jongmm.com/admin/jobPostingStatusUpdate');
        setStatusUpdateVisble(false);
        alert("수정이 완료되었습니다.");
    }

    const handleCancelStatus = () => {
        setStatusUpdateVisble(false);
    }

    return (
        <div>
            <h1>채용 공고 수정 페이지입니다.</h1>
            <div id='jobPostingUpdateBox'>
                <ul id='jobPostingList'>
                    {jobPostings.map(posting => (
                        <li key={posting.id}>
                            <p>{posting.status === 1 ? '채용중' : '채용 완료'}</p>
                            <p>{posting.title}</p>
                            <p>시작 : {posting.start_date}</p>
                            <p>마감 : {posting.end_date}</p>
                            <p id='editBtn' onClick={() => handleEditClick(posting)}>수정</p>
                            <p id='statusUpdateBtn' onClick={() => handleStatusClick(posting)}>상태 변경</p>
                        </li>
                    ))}
                </ul>
                {editBoxVisible && selectedPosting && <EditBox posting={selectedPosting} />}
                {statusUpdateVisible && <StatusUpdateBox onConfirm={handleConfirmStatus} onCancel={handleCancelStatus} />}
            </div>
        </div>
    )
}

export default JobPostingUpdate;