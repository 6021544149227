import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const AdminLogin = () => {
    const [adminId, setAdminId] = useState('');
    const [adminPassword, setAdminPassword] = useState('');
    const navigate = useNavigate();
    
    const handleLogin = async () => {
        try {
            if(adminId === "") {
                alert("아이디를 입력해주세요.");
            } else if(adminPassword === "") {
                alert("비밀번호를 입력해주세요.");
            } else {
                // const response = await axios.post('http://localhost:8081/admin/login', {adminId, adminPassword});
                const response = await axios.post('https://www.jongmm.com/admin/login', {adminId, adminPassword});
                if(response.data.success) {
                    localStorage.setItem('token', response.data.token);
                    navigate('/adminPage');
                } else {
                    alert('로그인 실패, 아이디와 비밀번호를 다시 확인하세요.');
                }
            }
        } catch (error) {
            console.error('Login error : ', error);
            alert('로그인 오류');
        }
    };
    return (
        <div>
            <h1>관리자 로그인 페이지입니다.</h1>
            <div>
                <input 
                type='text' 
                placeholder='아이디' 
                value={adminId} 
                onChange={(e) => setAdminId(e.target.value)} 
                />
                <input 
                type='password' 
                placeholder='비밀번호' 
                value={adminPassword} 
                onChange={(e) => setAdminPassword(e.target.value)} 
                />
                <button onClick={handleLogin}>로그인</button>
            </div>
        </div>
    )
}

export default AdminLogin;