import React from 'react'
import Header from '../../layout/Header'
import Nav from '../../layout/Nav'
import Footer from '../../layout/Footer'
import HeaderImage from '../../asset/image/PoolWaterproofingHeader.png'
import PoolImg1 from '../../asset/image/poolWaterproofing1.png'
import PoolImg2 from '../../asset/image/poolWaterproofing2.png'
import arrow from '../../asset/image/화살표.png'

import before1 from '../../asset/image/poolWaterproofing/poolBeforeImg1.png'
import after1 from '../../asset/image/poolWaterproofing/poolAfterImg1.png'
import before2 from '../../asset/image/poolWaterproofing/poolBeforeImg2.png'
import after2 from '../../asset/image/poolWaterproofing/poolAfterImg2.png'
import before3 from '../../asset/image/poolWaterproofing/poolBeforeImg3.png'
import after3 from '../../asset/image/poolWaterproofing/poolAfterImg3.png'
import before4 from '../../asset/image/poolWaterproofing/poolBeforeImg4.png'
import after4 from '../../asset/image/poolWaterproofing/poolAfterImg4.png'

const PoolWaterproofing = () => {
    return (
        <div id='commercialKitchenWaterproofing'>
            <Header />
            <Nav />
            <div id='header'>
                <img id='headerImage' src={HeaderImage} />
            </div>
            <div id='aboutTitleBox'>
                <p className='pageTitle'><span>Waterproof of pool</span><br />수영장 시트방수</p>
                <p id='line'></p>
            </div>
            <div className='productBox'>
                <div id='textBox'>
                    <p id='title'>기존 수영장의 문제점</p>
                </div>
                <div id='kitchenImgBox'>
                    <img src={PoolImg1} />
                    <img src={PoolImg2} />
                </div>
                <p id='kitchenImgText'>수영장에 타일과 페인트 방수 시공 시 모두 들뜨고 탈락하여 주기적인 보수작업 및 관리가 필요합니다.</p>
            </div>
            <div id='constructionExamplesBox'>
                <div className='examplesBox'>
                    <p id='title'>PVC시트 시공사례</p>
                    <p id='title1'>01 | 야외 수영장</p>
                    <p id='detailText'>지역센터의 공용 수영장 타일이 손상되고 오염되어 어린이나 다수의 인원이 사용하기에 적합하지 않은 상태였습니다. 일반적으로 야외 수영장 규모의 타일을 철거하게 된다면 많은 철거 비용이 발생하게 됩니다. 따라서 PVC시트로 시공하여 비용절감 및 오염문제, 안전문제를 해결하였습니다.</p>
                    <div id='examplesImgBox'>
                    <div id='beforeBox'>
                            <img src={before1} />
                            <p>PVC시트 시공 전</p>
                        </div>
                        <div id='arrowBox'>
                            <img src={arrow} />
                        </div>
                        <div id='afterBox'>
                            <img src={after1} />
                            <p>PVC시트 시공 후</p>
                        </div>
                    </div>
                    <div id='examplesImgBox'>
                        <div id='beforeBox'>
                            <img src={before2} />
                            <p>PVC시트 시공 전</p>
                        </div>
                        <div id='arrowBox'>
                            <img src={arrow} />
                        </div>
                        <div id='afterBox'>
                            <img src={after2} />
                            <p>PVC시트 시공 후</p>
                        </div>
                    </div>
                </div>
                <div className='examplesBox'>
                    <p id='title1'>02 | 실내 수영장</p>
                    <p id='detailText'>수영장으로 인해 건물 내로 누수가 발생했을 뿐만 아니라 기존 방수층이 들뜨고 오염되어 영업을 중단했던 현장입니다. PVC시트는 청소 및 관리에 있어 용이하므로 수영장을 간편하게 관리할 수 있는 장점이 있습니다.</p>
                    <div id='examplesImgBox'>
                        <div id='beforeBox'>
                            <img src={before3} />
                            <p>PVC시트 시공 전</p>
                        </div>
                        <div id='arrowBox'>
                            <img src={arrow} />
                        </div>
                        <div id='afterBox'>
                            <img src={after3} />
                            <p>PVC시트 시공 후</p>
                        </div>
                    </div>
                    <div id='examplesImgBox'>
                    <div id='beforeBox'>
                            <img src={before4} />
                            <p>PVC시트 시공 전</p>
                        </div>
                        <div id='arrowBox'>
                            <img src={arrow} />
                        </div>
                        <div id='afterBox'>
                            <img src={after4} />
                            <p>PVC시트 시공 후</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PoolWaterproofing;