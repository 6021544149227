import React from 'react';
import AdminAuth from './AdminAuth';


const Admin = () => {
    const { handleLogout } = AdminAuth();
    return (
        <div>
            <h1>관리자 페이지입니다.</h1>
            <div><a href='/jobPostingUpdate'>채용공고 업데이트</a></div>
            <div><a href='/quoteInquiry'>견적 문의</a></div>
            <div><a href='/jobApplications'>채용 문의</a></div>
            <div><a href='/performanceUpdate'>실적 게시판 업데이트</a></div>
            <div><a href='/performanceList'>실적 게시판</a></div>
            <button onClick={handleLogout}>로그아웃</button>
        </div>
    );
}

export default Admin;